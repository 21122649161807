import React, { useState, useRef } from "react"
import styled from "styled-components"
import {
  standardWrapper,
  H2Black,
  buttonThree,
  colors,
  bodyCopyOne,
} from "../../styles/helpers"

import InputText from "../FormParts/InputText"
import InputTextArea from "../FormParts/InputTextArea"
import { submitTheForm } from "../FormParts/FormUtilities/formFunctions"
// models //
import SubmitDisplay from "../FormParts/FormUtilities/SubmitDisplay"
import ErrorDisplay from "../FormParts/FormUtilities/ErrorDisplay"
import SuccessLetsChat from "../FormParts/FormUtilities/SuccessLetsChat"

import ReCAPTCHA from "react-google-recaptcha"

const SimpleFormSection = styled.section`
  .wrapper {
    ${standardWrapper};
  }

  .title {
    width: 100%;

    h2 {
      ${H2Black};
    }
  }

  .hero {
    width: 100%;
  }

  .form-main {
    width: 100%;

    .form-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }

    .connectDropdown {
      position: relative;
      width: 100%;
      padding-top: 3rem;

      label {
        display: block;
        width: 100%;
      }

      select {
        ${bodyCopyOne};
        display: block;
        width: 30rem;
        padding: 0.5rem;
        background: ${colors.grey};
        text-align: center;

        option {
          border: 1px solid red;
        }
      }
    }

    .captcha-container {
      width: 100%;
      margin-top: 2.5rem;

      p {
        ${bodyCopyOne};
        margin: 0;
        margin-bottom: 0.75rem;
        color: red;
      }
    }

    .form-submit {
      padding-top: 2.5rem;

      button {
        ${buttonThree};
      }
    }
  }
`

const SimpleForm = () => {
  const recaptchaRef = useRef(null)
  const [formId, setFormId] = useState(561)

  const [formData, updateFormData] = useState({
    yourName: "",
    yourNumber: "",
    yourEmail: "",
    yourMessage: "",
  })
  const [formStatus, updateFormStatus] = useState({
    submitting: false,
    errorWarnDisplay: false,
    success: false,
    errors: [],
    captachError: false,
  })
  const handleOnChange = e => {
    updateFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleOnSubmit = (e, formId, formData, updateFormStatus) => {
    e.preventDefault()
    const recaptchaValue = recaptchaRef.current.getValue()

    if (recaptchaValue === "") {
      updateFormStatus({
        ...formStatus,
        captachError: true,
      })
      return
    }

    updateFormStatus({
      ...formStatus,
      submitting: true,
      captachError: false,
    })

    submitTheForm(e, formId, formData, updateFormStatus)
  }

  return (
    <SimpleFormSection>
      <div className="wrapper">
        <div className="title">
          <h2>Connect with us</h2>
        </div>

        <form
          className="form-main"
          onSubmit={e => {
            handleOnSubmit(e, formId, formData, updateFormStatus)
          }}
        >
          <div className="form-wrapper">
            <div className="connectDropdown">
              <label htmlFor="connectWith">
                Connect with developer or Residents’ Association.
              </label>
              <select
                name="connectOptions"
                id="connectWith"
                onChange={e => {
                  setFormId(e.currentTarget.value)
                }}
              >
                <option value={561}>Developer</option>
                <option value={1763}>Residents’ Association</option>
              </select>
            </div>
            <InputText
              name="yourName"
              type="text"
              placeholder=""
              label="Name"
              value={formData.yourName}
              onChange={handleOnChange}
              errors={formStatus.errors}
              required={true}
              width="full"
              formSide=""
              textColor=""
            />
            <InputText
              name="yourEmail"
              type="email"
              placeholder=""
              label="Email"
              value={formData.yourEmail}
              onChange={handleOnChange}
              errors={formStatus.errors}
              required={true}
              width="half"
              formSide="left"
              textColor=""
            />
            <InputText
              name="yourNumber"
              type="text"
              placeholder=""
              label="Number"
              value={formData.yourNumber}
              onChange={handleOnChange}
              errors={formStatus.errors}
              required={true}
              width="half"
              formSide="right"
              textColor=""
            />

            <InputTextArea
              name="yourMessage"
              placeholder=""
              label="Send Us A Message"
              value={formData.yourMessage}
              onChange={handleOnChange}
              errors={formStatus.errors}
              required={true}
              rows={5}
              textColor=""
            />

            <div className="captcha-container">
              {formStatus.captachError && (
                <p>
                  The form will not submit until you have checked the reCAPCHA.
                </p>
              )}
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LdklZ0aAAAAAFRHnW_v0SvyR3NR7DLBHlQneVGg"
              />
            </div>

            <div className="form-submit">
              <button>submit</button>
            </div>
          </div>
        </form>
      </div>
      {formStatus.submitting && <SubmitDisplay />}
      {formStatus.errorWarnDisplay && (
        <ErrorDisplay formStatus={formStatus} stateSetter={updateFormStatus} />
      )}
      {formStatus.success && (
        <SuccessLetsChat
          updateFormData={updateFormData}
          updateFormStatus={updateFormStatus}
        />
      )}
    </SimpleFormSection>
  )
}

export default SimpleForm
