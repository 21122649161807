import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Hero from "../components/Connect/Hero"
import TwoColumns from "../components/Connect/TwoColumns"
import SimpleForm from "../components/Connect/SimpleForm"
import Callout from "../components/Connect/Callout"

const Connect = props => {
  const { seoInfo, hero, twoColumns, callOut } = props.data
  const location = props.location.pathname
  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={props.location.pathname}
      />
      <Hero hero={hero} />
      <TwoColumns data={twoColumns} />
      <SimpleForm />
      <Callout data={callOut} />
    </Layout>
  )
}

export const connectQuery = graphql`
  query connectPage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    hero: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_hohr_bg {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_hohr_content
        _cco_hohr_button_required
        _coo_hohr_btn_text
        _coo_hohr_page_link_two
      }
    }

    twoColumns: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_tocol_left_title
        _coo_tocol_left_content
        _coo_tocol_right_title
        _coo_tocol_right_content
      }
    }

    callOut: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_hoca_top_title
        _coo_hoca_top_content
        _coo_hoca_top_btn_text
        _coo_hoca_top_btn_link_two
        _coo_hoca_top_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_hoca_bg_color
      }
    }
  }
`

export default Connect
