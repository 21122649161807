import React from "react"
import styled from "styled-components"
import { standardWrapper, H2Black, B1Black } from "../../styles/helpers"

const TwoColumnsSection = styled.section`
  padding: 2rem 0;
  .wrapper {
    ${standardWrapper};
  }

  .column {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
    }

    &.columnLeft {
      @media (min-width: 768px) {
        margin-right: 4rem;
      }
    }

    .columnRight {
      @media (min-width: 768px) {
        margin-left: 4rem;
      }
    }

    &__title {
      h2 {
        ${H2Black};
      }
    }

    &__wysiwyg {
      p {
        ${B1Black};
      }
    }
  }
`

const TwoColumns = ({ data }) => {
  const leftTitle = data.acf._coo_tocol_left_title
  const leftContent = data.acf._coo_tocol_left_content
  const rightTitle = data.acf._coo_tocol_right_title
  const rightContent = data.acf._coo_tocol_right_content
  return (
    <TwoColumnsSection>
      <div className="wrapper">
        <div className="column columnLeft">
          <div className="column__title">
            <h2>{leftTitle}</h2>
          </div>
          <div
            className="column__wysiwyg"
            dangerouslySetInnerHTML={{ __html: leftContent }}
          />
        </div>
        <div className="column columnRight">
          <div className="column__title">
            <h2>{rightTitle}</h2>
          </div>
          <div
            className="column__wysiwyg"
            dangerouslySetInnerHTML={{ __html: rightContent }}
          />
        </div>
      </div>
    </TwoColumnsSection>
  )
}

export default TwoColumns
